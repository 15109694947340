import React from "react";
import Layout from "../components/layout"
import Container from "../components/container";
import Button from "../components/button";
import GlobalStyle from "../globalStyling"
import Theme from "../constants/theme";
import styled from 'styled-components';

export default function Home() {
  return (
      <>
      <Theme>
        <GlobalStyle />
        <Layout menuItems={false}>
          <Content>
            <Container>
              <Header>Oeps, deze weg loopt dood</Header>
              <Subheader>Trek een sprintje terug naar de start.</Subheader>
              <Button title="Ga terug naar home" link="/"></Button>
              <Image src={require(`../assets/img/running_course.jpg`)} />
            </Container>
          </Content>
        </Layout>
      </Theme>
        
    </>
  )
}

const Content = styled.div`
  padding-top:70px;
  min-height:calc(100vh - 104px);
`

const Header = styled.h1`
  margin-top:32px;
  margin-bottom:16px;
  line-height:40px;

  @media screen and (min-width:${props => props.theme.devices.tablet}){
    margin-top:40px;
    margin-bottom:40px;
    font-size:50px;
    line-height:58px;
  }
`

const Subheader = styled.p`
  margin-bottom:24px;
  @media screen and (min-width:${props => props.theme.devices.tablet}){
    margin-bottom:40px;
    font-size:36px;
    line-height:40px;
  }
`

const Image = styled.img`
  max-width: 100%;
  display:block;
  margin-top:24px;
  margin-bottom:24px;

  @media screen and (min-width:${props => props.theme.devices.tablet}){
    max-height:400px;
    margin-top:40px;
    margin-bottom:40px;
  }
`